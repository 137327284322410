import React, { useState, useEffect } from 'react';
import VendorSideLayout from './VendorsSideLayout';
import VendortopLayout from './VendortopLayout';

import './profile.css';

export default function Profile() {
	const [profileData, setProfileData] = useState(null);
	    const [referralUsers, setReferralUsers] = useState([]);
	const phoneNumber = sessionStorage.getItem('phoneNumber');
	console.log(phoneNumber);
  useEffect(() => {
    async function fetchProfileData() {
      try {
		 
		  const profile_id = phoneNumber;
        const response = await fetch(`https://api.pickmyproperty.com/profile/${profile_id}`); 
        const data = await response.json();
		const profile_data = data.data;
        setProfileData(profile_data);
		const datareferralCode = profile_data.referralCode;
                const referralResponse = await fetch(`https://api.pickmyproperty.com/referralUsers/${datareferralCode}`);
                const referralData = await referralResponse.json();
				console.log(referralData.data)
                setReferralUsers(referralData.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    fetchProfileData();
  }, []);



 return (
  <>
    <div className="wrapper-full row">
      <div className="wrapper-third col-md-2">
        <VendorSideLayout />
      </div>
      <div className="wrapper-seven col-md-10">
        <VendortopLayout />
        <div className="profile__data">
          <div className="container-data">
            {profileData && (
              <>
                <div className="profile-container">
                  <div className="person-box">
                    <div className="person-box--left">
                      <div className="person-img">
                        <img
                          src="https://i.ibb.co/tp1vQ8T/man-person-icon.png"
                          alt="Jessica Potter"
                        />
                      </div>
                      <div className="person-name">
                        <table className="table-profile">
                          <tbody>
                            <tr>
                              <td>Username</td>
                              <td>{profileData.username}</td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>{profileData.phone_number}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{profileData.email}</td>
                            </tr>
                            <tr>
                              <td>Type</td>
                              <td>{profileData.type}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="links">
                        <ul>
                          <li>
                            <a href="https://codepen.io/batuhangulgor" target="_blank" rel="noopener noreferrer">
                              Follow
                            </a>
                          </li>
                          <li>
                            <a href="mailto:gulgor.batu@gmail.com">Message</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="person-box--right">
                      <a href="#" className="links link-1">
                        <div className="right-content">
                          <p>₹{profileData.Userpoint}</p>
                          <p>Rupees</p>
                        </div>
                      </a>
                      <a href="#" className="links link-2">
                        <div className="right-content">
                          <p>{profileData.referralPoint}</p>
                          <p>Cashback incentive</p>
                        </div>
                      </a>
                      <a href="#" className="links link-2">
                        <div className="right-content">
                          <p>{profileData.referralCode}</p>
                          <p>Referral Code</p>
                        </div>
                      </a>
                      <a href="#" className="links link-3">
                        <div className="right-content">
                          <p>Referral link</p>
                          <p className="link-copy">{`http://localhost:3000/Loginpanel?refer=${profileData.referralCode}`}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="referral-users">
                  <h2>Sub wallet (only 20,000)</h2>
                  <ul className="associated_users">
                    {referralUsers.map((user, index) => (
                      <li key={index}>
                        <p className="user-nfo">
                          <span>Name : {user.username}</span>
                          <span>Referral Code : {user.referralCode}</span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);

}
