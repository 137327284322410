// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-field {
    display: none;
}
.width-full-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    margin: 0 auto;
}

.title-change {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/Component/vendor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".hidden-field {\r\n    display: none;\r\n}\r\n.width-full-btn {\r\n    height: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 80px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.title-change {\r\n    text-align: center;\r\n    font-size: 24px;\r\n    text-transform: uppercase;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
