import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './form.css';
import Axios  from "axios";

const VendorSignup = () => {
    const [showSuccess, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    phone_number: '',
    referralCode: ''
  });
  
  /* ============================================ */

  


  /* ============================================ */


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.pickmyproperty.com/vendor_signup', formData);
      console.log(response.data.message);
      setSuccess(true);
    } catch (error) {
      console.error('Registration failed:', error.response.data);
    }
  };
return (
    <>
      <div className="container-form-setup">
        <div className="wrapper-form vendor-form">
          <div className="title-text">
            <div className="title signup">Signup Form</div>
          </div>
          <div className="form-container">
         
            <div className="form-inner">

              <form onSubmit={handleSubmit} className="signup">
                <h3 className="signup-heading">Signup Form (Vendor)</h3>
                <div className='field'>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='field'>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='field'>
                  <input
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='field'>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                {showSuccess && (
                  <div className="text-danger mt-2">
                    Vendor added successfully, Your account will be active soon.
                  </div>
                )}
                <div className='field'>
                  <button className="button-submit" type="submit">Sign Up</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorSignup;
