// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-background {
  
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  overflow: hidden;
  z-index: -1;
}

.video-background video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.text-overlay h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.text-overlay p {
  font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/VideoBackground.css"],"names":[],"mappings":"AAAA;;EAEE,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".video-background {\r\n  \r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 500px;\r\n  overflow: hidden;\r\n  z-index: -1;\r\n}\r\n\r\n.video-background video {\r\n  min-width: 100%;\r\n  min-height: 100%;\r\n  width: auto;\r\n  height: auto;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.text-overlay {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  text-align: center;\r\n  color: white;\r\n}\r\n\r\n.text-overlay h1 {\r\n  font-size: 3em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.text-overlay p {\r\n  font-size: 1.5em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
