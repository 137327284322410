import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios'; 
import logo from './site_logo-site.jpg';
import useAuth from './useAuth';
const CustomerForm = () => {
  const [name, setName] = useState('Customer Login');
  const navigate = useNavigate();

  useEffect(() => {
    const storedValue = sessionStorage.getItem('phoneNumber');
    const userType = sessionStorage.getItem('userType');
    const username = sessionStorage.getItem('username');
    console.log('Stored value:', userType);

    if (username) {
      setName(username);
    } else {
      setName('Customer Login');
    }
	  if (storedValue) {
      setFormData(prevFormData => ({
        ...prevFormData,
        p_phone: storedValue
      }));
    }
  }, []);

  const logout = () => {
    sessionStorage.clear();
    navigate('/Loginpanel');
  };

  const [data, setData] = useState([]);
   const [success, setSuccess] = useState(false);
  const [proplocation, setProplocation] = useState('');
  const [propid, setPropid] = useState('');
  const [vendor, setVendor] = useState('');
  const [propertyDetails, setPropertyDetails] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:4300/all_property')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const [formData, setFormData] = useState({
    p_id: '',
    p_name: '',
    p_Location: '',
    p_customername: '',
    p_vendor: '',
    p_phone: '',
    p_email: '',
    p_images: [],
    p_approved: ''
  });

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      p_Location: proplocation,
      p_vendor: vendor,
	   p_id: propid
    }));
  }, [proplocation, vendor]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "p_name") {
      const selectedOption = e.target.options[e.target.selectedIndex].text;
	  console.log(selectedOption)
      setFormData({
        ...formData,
        p_name: selectedOption
      });

      if (value) {
        try {
          const propertyData = await axios.get(`http://localhost:4300/propertyData/${value}`);
          setPropertyDetails(propertyData.data.data);
          setProplocation(propertyData.data.data.propertyLocation);
          setPropid(propertyData.data.data.Id);
          setVendor(propertyData.data.data.propertyVendor);
        } catch (error) {
          console.error('Error fetching property details:', error);
        }
      } else {
        setPropertyDetails(null);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

 
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      p_images: files
    });
  };
const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'p_images') {
        formData[key].forEach(file => {
          formDataToSend.append('p_images', file);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
		console.log(formData)
    axios.post('http://localhost:4300/submit_property', formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Form submitted successfully:', response.data.response);
	   if (response.data.response === 'OK') {
        setSuccess(true);
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    });
  };


  return (
    <>
      <div className="header">
        <div className="row-header">
          <div className="row-col-logo">
            <div className="logo-inner">
              <img src={logo} alt="Site Logo" />
            </div>
          </div>
          <div className="row-col-menu">
            <ul>
              <li className="loan-app">
                <img
                  src="https://static.makaan.com/images/personalLoansHooks/Personal_Loan_Desktop_Home_Nav.22990e95.png?width=96&height=20"
                  alt="Loan App"
                />
              </li>
              <li className="download-s-app">
                <span
                  data-url="/download-mobileapp"
                  data-link-type="Other"
                  data-link-name="download app"
                  data-type="download-app"
                  title=""
                  className="d-show span-link"
                >
                  <span className="app-img" />
                  <span className="txt"> Download app</span>
                </span>
              </li>
              <li className="sellersitelink">
                <a
                  href="https://seller.makaan.com/postProperty"
                  data-link-type="Other"
                  data-link-name="list your property"
                  data-type="track-sell-your-property"
                  target="_blank"
                >
                  <span className="list-icon" />
                  <span className="txt">
                    Sell/Rent Property<sup> Free</sup>
                  </span>
                </a>
              </li>
              <li className="login">
                <Link to="/Loginpanel">{name}</Link>
              </li>
              {name !== 'Customer Login' && (
                <li className="log-out" onClick={logout}>
                  <span>Logout</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h2>Customer Property Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="p_name">Property Name</label>
            <select
              className="form-control"
              id="p_name"
              name="p_name"
              value={formData.p_name}
              onChange={handleChange}
            >
              <option value="">Select Property Name</option>
              {data.map((property) => (
                <option key={property.Id} value={property.Id}>
                  {property.propertyName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="p_Location">Property Location</label>
            <input
              type="text"
              className="form-control"
              id="p_Location"
              name="p_Location"
              value={formData.p_Location}
              onChange={handleChange}
              placeholder="Enter Property Location"
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_id">Property ID</label>
            <input
              type="text"
              className="form-control"
              id="p_id"
              name="p_id"
              value={propid}
              onChange={handleChange}
              placeholder="Enter Property ID"
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_vendor">Property Vendor</label>
            <input
              type="text"
              className="form-control"
              id="p_vendor"
              name="p_vendor"
              value={formData.p_vendor}
              onChange={handleChange}
              placeholder="Enter Property Vendor Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_customername">Customer Name</label>
            <input
              type="text"
              className="form-control"
              id="p_customername"
              name="p_customername"
              value={formData.p_customername}
              onChange={handleChange}
              placeholder="Enter Customer Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="p_phone"
              name="p_phone"
              value={formData.p_phone}
              onChange={handleChange}
              placeholder="Enter Phone Number" readOnly 
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_email">Email</label>
            <input
              type="email"
              className="form-control"
              id="p_email"
              name="p_email"
              value={formData.p_email}
              onChange={handleChange}
              placeholder="Enter Email Address"
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_image">Property Image</label>
            <input
              type="file"
              className="form-control-file"
              id="p_image"
              name="p_image"
              onChange={handleFileChange}
			  multiple
            />
          </div>
          <div className="form-group">
            <label htmlFor="p_approved">Approved</label>
            <select
              className="form-control"
              id="p_approved"
              name="p_approved"
              value={formData.p_approved}
              onChange={handleChange}
            >
              <option value="">Select Approval Status</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
	 {success && (
          <span className="success-message">
            Your Form is submitted
          </span>
        )}
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </>
  );
};

export default CustomerForm;
