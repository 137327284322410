import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabContainer, Row, Col, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './blog.css';
import TopLayout from './TopLayout';
import SidebarLayout from './SidebarLayout';
import axios from 'axios';

export default function CustomerDetails() {
  const [referralUsers, setReferralUsers] = useState([]);
  const [transaction, setReferraltransaction] = useState([]);
  
  const [profileData, setProfileData] = useState({
    id: '',
    username: '',
    phone_number: '',
    email: '',
    password: '',
    type: '',
    referralCode: '',
    referralPoint: '',
    referralUser_code: '',
    Userpoint: '',
    UserpointByadmin: ''
  });

  const location = useLocation();
  console.log('=================');
  console.log(location.state.phonenumber);

  useEffect(() => {
    async function fetchCustomerData() {
      try {
        const customer_number = location.state.phonenumber;
        const customerData = await fetch(`https://api.pickmyproperty.com/profile/${customer_number}`);
        const customer_data = await customerData.json();
        const profile_data = customer_data.data;
        setProfileData(profile_data);

        if (profile_data.referralCode) {
          const referralUsersData = await fetch(`https://api.pickmyproperty.com/referralUsers/${profile_data.referralCode}`);
          const referral_users = await referralUsersData.json();
          setReferralUsers(referral_users.data);
        }

        if (profile_data.id) {
          console.log(profile_data.id)
          const referraltransaction = await fetch(`https://api.pickmyproperty.com/transaction/${profile_data.id}`);
          const referraltransaction_data = await referraltransaction.json();
          console.log(referraltransaction_data)
          setReferraltransaction(referraltransaction_data.data);
        }

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    fetchCustomerData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', profileData);
    axios.post(`https://api.pickmyproperty.com/wallet_update`, profileData)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  if (!profileData) {
    return <div>Loading...</div>;
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  };

  return (
    <div className="wrapper-full row">
      <div className="wrapper-third col-md-2">
        <SidebarLayout />
      </div>
      <div className="wrapper-seven col-md-10">
        <TopLayout />
        <div className="container-mt-5">
          <h2>Customer Profile</h2>
          <TabContainer defaultActiveKey="customerData">
            <Row className="sidebar-row">
			
              <Col className="sidebar-css" sm={3}>
			  <div className="sidebar-border">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="customerData">Customer Data</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="referralUsers">Users who used Referral Code: {profileData.referralCode}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="transactions">Transaction For: {profileData.username}</Nav.Link>
                  </Nav.Item>
                </Nav>
				  </div>
              </Col>
			
              <Col className="content-css" sm={9}>
			   <div className="sidebar-border">
                <Tab.Content>
                  <Tab.Pane eventKey="customerData">
                    <div className="profile-data-form">
                      <h3>Customer Data</h3>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="id">ID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="id"
                            name="id"
                            value={profileData.id}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="username">Username</label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            value={profileData.username}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone_number">Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone_number"
                            name="phone_number"
                            value={profileData.phone_number}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={profileData.email}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            value={profileData.password}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="type">Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="type"
                            name="type"
                            value={profileData.type}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="referralCode">Referral Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="referralCode"
                            name="referralCode"
                            value={profileData.referralCode}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="referralPoint">Referral Point</label>
                          <input
                            type="text"
                            className="form-control"
                            id="referralPoint"
                            name="referralPoint"
                            value={profileData.referralPoint}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="referralUser_code">Referral User Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="referralUser_code"
                            name="referralUser_code"
                            value={profileData.referralUser_code}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="Userpoint">User Point</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Userpoint"
                            name="Userpoint"
                            value={profileData.Userpoint}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="UserpointByadmin">Assign Point</label>
                          <input
                            type="text"
                            className="form-control"
                            id="UserpointByadmin"
                            name="UserpointByadmin"
                            value={profileData.UserpointByadmin}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </form>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="referralUsers">
                    <div className="UserRefferal">
                      {referralUsers && referralUsers.length > 0 && (
                        <div className="user-referrals">
                          <h3>Users who used Referral Code: {profileData.referralCode}</h3>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Username</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Type</th>
                                <th>Referral User Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              {referralUsers.map((user) => (
                                <tr key={user.id}>
                                  <td>{user.username}</td>
                                  <td>{user.phone_number}</td>
                                  <td>{user.email}</td>
                                  <td>{user.type}</td>
                                  <td>{user.referralCode}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="transactions">
                    <div className="UserRefferal">
                      {transaction && transaction.length > 0 && (
                        <div className="user-referrals">
                          <h3>Transaction For: {profileData.username}</h3>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>USER ID</th>
                                <th>Previous User Point</th>
                                <th>User Point By Admin</th>
                                <th>New User Point</th>
                                <th>Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaction.map((user) => (
                                <tr key={user.user_id}>
                                  <td>{user.user_id}</td>
                                  <td>{user.previous_user_point}</td>
                                  <td>{user.user_point_by_admin}</td>
                                  <td>{user.new_user_point}</td>
                                  <td>{user.timestamp}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
				
                </Tab.Content>
				  </div>
              </Col>
            </Row>
          </TabContainer>
        </div>
      </div>
    </div>
  );
}
