function List()
{
	return(
	<>
  <div className="Property-list-container">
    <div className="property-list">
      <div className="property-inner">
        <div className="row-property">
          <div className="property-image">
            <img src="https://static.makaan.com/1/1948029/297/images-for-project-154355646.jpeg?width=1024&height=576" />
          </div>
          <div className="property-data">
            <div className="property-info">
              <div className="property--info">
                <span className="property-name">
                  4 BHK Apartment for sale Ambika LA Parisian
                </span>
                <span className="bottomd-detail">Sector 66 Mohali</span>
              </div>
              <div className="property-badge">
                <span>Rera</span>
              </div>
            </div>
            <div className="property-table">
              <table className="listing-highlights">
                <thead>
                  <tr>
                    <td colSpan={2}>Specifications</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hcol">
                    <td className="price">
                      <div data-type="price-link">
                        <sup className="rupee currency"> </sup>
                        <span
                          className="val"
                          itemProp="offers"
                          itemScope=""
                          itemType="http://schema.org/Offer"
                        >
                          <meta
                            itemProp="url"
                            content="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                          />
                          <meta itemProp="price" content={20000000} />
                          <meta itemProp="priceCurrency" content="INR" />2
                        </span>
                        <span className="unit"> Cr</span>
                      </div>
                    </td>
                    <td className="lbl rate">6,920 / sq ft</td>
                  </tr>
                  <tr className="hcol">
                    <td className="size">
                      <span className="val">2890 </span>
                    </td>
                    <td className="lbl">Area in sq ft</td>
                  </tr>
                  <tr className="hcol w44">
                    <td className="val">Under Construction</td>
                    <td className="lbl">Construction Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="property-listing">
              <ul className="listing-details">
                <li className="keypoint" title="Possession by">
                  <span>
                    <strong>
                      <span>Possession by Dec 2023</span>
                    </strong>
                  </span>
                </li>
                <li className="keypoint" title="Bathrooms">
                  <span>4 Bathrooms</span>
                </li>
                <li className="keypoint">
                  <span>Resale </span>
                </li>
              </ul>
            </div>
            <div className="property-description">
              <p>
                4 BHK Apartment for sale in Sector 66, Mohali - contact now,
              </p>
            </div>
            <div className="btn-wrap">
              <div className="btn-icons">
                <span
                  data-lazymodule="shortlist"
                  data-type="fav-btn"
                  data-id={20376158}
                  id="shortlist-20376158"
                  className="favbtn serpfavd"
                  title="shortlist this property"
                  data-module="shortlist"
                >
                  <i className="icon-makaan" />
                  <i className="icon-makaanfill" />
                </span>
                <span
                  className="serpShare shareitem"
                  data-url="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                  data-type="social-share"
                  title="share"
                >
                  <i className="icon-share" />
                </span>
              </div>
              <div className="callwrap">
                <a
                  className="txtbtn"
                  data-call-now=""
                  data-type="openLeadFormViewPhone"
                  data-step="VIEW_PHONE_MORPHED"
                >
                  View Phone
                </a>
                <a
                  className="cbtn cbtn-p"
                  data-call-now=""
                  data-type="openLeadForm"
                >
                  {" "}
                  Connect Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="divider" />
    <div className="property-list">
      <div className="property-inner">
        <div className="row-property">
          <div className="property-image">
            <img src="https://static.makaan.com/1/1948029/297/images-for-project-154355646.jpeg?width=1024&height=576" />
          </div>
          <div className="property-data">
            <div className="property-info">
              <div className="property--info">
                <span className="property-name">
                  4 BHK Apartment for sale Ambika LA Parisian
                </span>
                <span className="bottomd-detail">Sector 66 Mohali</span>
              </div>
              <div className="property-badge">
                <span>Rera</span>
              </div>
            </div>
            <div className="property-table">
              <table className="listing-highlights">
                <thead>
                  <tr>
                    <td colSpan={2}>Specifications</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hcol">
                    <td className="price">
                      <div data-type="price-link">
                        <sup className="rupee currency"> </sup>
                        <span
                          className="val"
                          itemProp="offers"
                          itemScope=""
                          itemType="http://schema.org/Offer"
                        >
                          <meta
                            itemProp="url"
                            content="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                          />
                          <meta itemProp="price" content={20000000} />
                          <meta itemProp="priceCurrency" content="INR" />2
                        </span>
                        <span className="unit"> Cr</span>
                      </div>
                    </td>
                    <td className="lbl rate">6,920 / sq ft</td>
                  </tr>
                  <tr className="hcol">
                    <td className="size">
                      <span className="val">2890 </span>
                    </td>
                    <td className="lbl">Area in sq ft</td>
                  </tr>
                  <tr className="hcol w44">
                    <td className="val">Under Construction</td>
                    <td className="lbl">Construction Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="property-listing">
              <ul className="listing-details">
                <li className="keypoint" title="Possession by">
                  <span>
                    <strong>
                      <span>Possession by Dec 2023</span>
                    </strong>
                  </span>
                </li>
                <li className="keypoint" title="Bathrooms">
                  <span>4 Bathrooms</span>
                </li>
                <li className="keypoint">
                  <span>Resale </span>
                </li>
              </ul>
            </div>
            <div className="property-description">
              <p>
                4 BHK Apartment for sale in Sector 66, Mohali - contact now,
              </p>
            </div>
            <div className="btn-wrap">
              <div className="btn-icons">
                <span
                  data-lazymodule="shortlist"
                  data-type="fav-btn"
                  data-id={20376158}
                  id="shortlist-20376158"
                  className="favbtn serpfavd"
                  title="shortlist this property"
                  data-module="shortlist"
                >
                  <i className="icon-makaan" />
                  <i className="icon-makaanfill" />
                </span>
                <span
                  className="serpShare shareitem"
                  data-url="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                  data-type="social-share"
                  title="share"
                >
                  <i className="icon-share" />
                </span>
              </div>
              <div className="callwrap">
                <a
                  className="txtbtn"
                  data-call-now=""
                  data-type="openLeadFormViewPhone"
                  data-step="VIEW_PHONE_MORPHED"
                >
                  View Phone
                </a>
                <a
                  className="cbtn cbtn-p"
                  data-call-now=""
                  data-type="openLeadForm"
                >
                  {" "}
                  Connect Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="divider" />
    <div className="property-list">
      <div className="property-inner">
        <div className="row-property">
          <div className="property-image">
            <img src="https://static.makaan.com/1/1948029/297/images-for-project-154355646.jpeg?width=1024&height=576" />
          </div>
          <div className="property-data">
            <div className="property-info">
              <div className="property--info">
                <span className="property-name">
                  4 BHK Apartment for sale Ambika LA Parisian
                </span>
                <span className="bottomd-detail">Sector 66 Mohali</span>
              </div>
              <div className="property-badge">
                <span>Rera</span>
              </div>
            </div>
            <div className="property-table">
              <table className="listing-highlights">
                <thead>
                  <tr>
                    <td colSpan={2}>Specifications</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hcol">
                    <td className="price">
                      <div data-type="price-link">
                        <sup className="rupee currency"> </sup>
                        <span
                          className="val"
                          itemProp="offers"
                          itemScope=""
                          itemType="http://schema.org/Offer"
                        >
                          <meta
                            itemProp="url"
                            content="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                          />
                          <meta itemProp="price" content={20000000} />
                          <meta itemProp="priceCurrency" content="INR" />2
                        </span>
                        <span className="unit"> Cr</span>
                      </div>
                    </td>
                    <td className="lbl rate">6,920 / sq ft</td>
                  </tr>
                  <tr className="hcol">
                    <td className="size">
                      <span className="val">2890 </span>
                    </td>
                    <td className="lbl">Area in sq ft</td>
                  </tr>
                  <tr className="hcol w44">
                    <td className="val">Under Construction</td>
                    <td className="lbl">Construction Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="property-listing">
              <ul className="listing-details">
                <li className="keypoint" title="Possession by">
                  <span>
                    <strong>
                      <span>Possession by Dec 2023</span>
                    </strong>
                  </span>
                </li>
                <li className="keypoint" title="Bathrooms">
                  <span>4 Bathrooms</span>
                </li>
                <li className="keypoint">
                  <span>Resale </span>
                </li>
              </ul>
            </div>
            <div className="property-description">
              <p>
                4 BHK Apartment for sale in Sector 66, Mohali - contact now,
              </p>
            </div>
            <div className="btn-wrap">
              <div className="btn-icons">
                <span
                  data-lazymodule="shortlist"
                  data-type="fav-btn"
                  data-id={20376158}
                  id="shortlist-20376158"
                  className="favbtn serpfavd"
                  title="shortlist this property"
                  data-module="shortlist"
                >
                  <i className="icon-makaan" />
                  <i className="icon-makaanfill" />
                </span>
                <span
                  className="serpShare shareitem"
                  data-url="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                  data-type="social-share"
                  title="share"
                >
                  <i className="icon-share" />
                </span>
              </div>
              <div className="callwrap">
                <a
                  className="txtbtn"
                  data-call-now=""
                  data-type="openLeadFormViewPhone"
                  data-step="VIEW_PHONE_MORPHED"
                >
                  View Phone
                </a>
                <a
                  className="cbtn cbtn-p"
                  data-call-now=""
                  data-type="openLeadForm"
                >
                  {" "}
                  Connect Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="divider" />
    <div className="property-list">
      <div className="property-inner">
        <div className="row-property">
          <div className="property-image">
            <img src="https://static.makaan.com/1/1948029/297/images-for-project-154355646.jpeg?width=1024&height=576" />
          </div>
          <div className="property-data">
            <div className="property-info">
              <div className="property--info">
                <span className="property-name">
                  4 BHK Apartment for sale Ambika LA Parisian
                </span>
                <span className="bottomd-detail">Sector 66 Mohali</span>
              </div>
              <div className="property-badge">
                <span>Rera</span>
              </div>
            </div>
            <div className="property-table">
              <table className="listing-highlights">
                <thead>
                  <tr>
                    <td colSpan={2}>Specifications</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hcol">
                    <td className="price">
                      <div data-type="price-link">
                        <sup className="rupee currency"> </sup>
                        <span
                          className="val"
                          itemProp="offers"
                          itemScope=""
                          itemType="http://schema.org/Offer"
                        >
                          <meta
                            itemProp="url"
                            content="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                          />
                          <meta itemProp="price" content={20000000} />
                          <meta itemProp="priceCurrency" content="INR" />2
                        </span>
                        <span className="unit"> Cr</span>
                      </div>
                    </td>
                    <td className="lbl rate">6,920 / sq ft</td>
                  </tr>
                  <tr className="hcol">
                    <td className="size">
                      <span className="val">2890 </span>
                    </td>
                    <td className="lbl">Area in sq ft</td>
                  </tr>
                  <tr className="hcol w44">
                    <td className="val">Under Construction</td>
                    <td className="lbl">Construction Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="property-listing">
              <ul className="listing-details">
                <li className="keypoint" title="Possession by">
                  <span>
                    <strong>
                      <span>Possession by Dec 2023</span>
                    </strong>
                  </span>
                </li>
                <li className="keypoint" title="Bathrooms">
                  <span>4 Bathrooms</span>
                </li>
                <li className="keypoint">
                  <span>Resale </span>
                </li>
              </ul>
            </div>
            <div className="property-description">
              <p>
                4 BHK Apartment for sale in Sector 66, Mohali - contact now,
              </p>
            </div>
            <div className="btn-wrap">
              <div className="btn-icons">
                <span
                  data-lazymodule="shortlist"
                  data-type="fav-btn"
                  data-id={20376158}
                  id="shortlist-20376158"
                  className="favbtn serpfavd"
                  title="shortlist this property"
                  data-module="shortlist"
                >
                  <i className="icon-makaan" />
                  <i className="icon-makaanfill" />
                </span>
                <span
                  className="serpShare shareitem"
                  data-url="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                  data-type="social-share"
                  title="share"
                >
                  <i className="icon-share" />
                </span>
              </div>
              <div className="callwrap">
                <a
                  className="txtbtn"
                  data-call-now=""
                  data-type="openLeadFormViewPhone"
                  data-step="VIEW_PHONE_MORPHED"
                >
                  View Phone
                </a>
                <a
                  className="cbtn cbtn-p"
                  data-call-now=""
                  data-type="openLeadForm"
                >
                  {" "}
                  Connect Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="divider" />
    <div className="property-list">
      <div className="property-inner">
        <div className="row-property">
          <div className="property-image">
            <img src="https://static.makaan.com/1/1948029/297/images-for-project-154355646.jpeg?width=1024&height=576" />
          </div>
          <div className="property-data">
            <div className="property-info">
              <div className="property--info">
                <span className="property-name">
                  4 BHK Apartment for sale Ambika LA Parisian
                </span>
                <span className="bottomd-detail">Sector 66 Mohali</span>
              </div>
              <div className="property-badge">
                <span>Rera</span>
              </div>
            </div>
            <div className="property-table">
              <table className="listing-highlights">
                <thead>
                  <tr>
                    <td colSpan={2}>Specifications</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hcol">
                    <td className="price">
                      <div data-type="price-link">
                        <sup className="rupee currency"> </sup>
                        <span
                          className="val"
                          itemProp="offers"
                          itemScope=""
                          itemType="http://schema.org/Offer"
                        >
                          <meta
                            itemProp="url"
                            content="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                          />
                          <meta itemProp="price" content={20000000} />
                          <meta itemProp="priceCurrency" content="INR" />2
                        </span>
                        <span className="unit"> Cr</span>
                      </div>
                    </td>
                    <td className="lbl rate">6,920 / sq ft</td>
                  </tr>
                  <tr className="hcol">
                    <td className="size">
                      <span className="val">2890 </span>
                    </td>
                    <td className="lbl">Area in sq ft</td>
                  </tr>
                  <tr className="hcol w44">
                    <td className="val">Under Construction</td>
                    <td className="lbl">Construction Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="property-listing">
              <ul className="listing-details">
                <li className="keypoint" title="Possession by">
                  <span>
                    <strong>
                      <span>Possession by Dec 2023</span>
                    </strong>
                  </span>
                </li>
                <li className="keypoint" title="Bathrooms">
                  <span>4 Bathrooms</span>
                </li>
                <li className="keypoint">
                  <span>Resale </span>
                </li>
              </ul>
            </div>
            <div className="property-description">
              <p>
                4 BHK Apartment for sale in Sector 66, Mohali - contact now,
              </p>
            </div>
            <div className="btn-wrap">
              <div className="btn-icons">
                <span
                  data-lazymodule="shortlist"
                  data-type="fav-btn"
                  data-id={20376158}
                  id="shortlist-20376158"
                  className="favbtn serpfavd"
                  title="shortlist this property"
                  data-module="shortlist"
                >
                  <i className="icon-makaan" />
                  <i className="icon-makaanfill" />
                </span>
                <span
                  className="serpShare shareitem"
                  data-url="https://www.makaan.com/mohali/ambika-realcon-la-parisian-in-sector-66-20376158/4bhk-4t-2890-sqft-apartment"
                  data-type="social-share"
                  title="share"
                >
                  <i className="icon-share" />
                </span>
              </div>
              <div className="callwrap">
                <a
                  className="txtbtn"
                  data-call-now=""
                  data-type="openLeadFormViewPhone"
                  data-step="VIEW_PHONE_MORPHED"
                >
                  View Phone
                </a>
                <a
                  className="cbtn cbtn-p"
                  data-call-now=""
                  data-type="openLeadForm"
                >
                  {" "}
                  Connect Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n.row-property {\n    display: flex;\n}\n\n.property-image {\n    flex: 0 0 25%;\n}\n.property-data {\n    flex: 0 0 75%;\n    padding-left: 10px;\n}\n.Property-list-container {\n    max-width: 820px;\n    margin: 0 auto;\n}\n\n.property-image img {\n    max-width: 100%;\n\theight:100%;\n}\n\n.property-info {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\nul.listing-details {\n    display: inline-block;\n    max-width: 96%;\n    padding-top: 11px;\n    width: 100%;\n    list-style: none;\n    padding-left: 0px;\n}\n\nli.keypoint {\n    display: inline-block;\n    padding: 0 6px;\n    border-right: solid 1px #ccc;\n    font-size: 14px;\n    line-height: 1.1;\n}\n\n.property-description p {\n    display: inline;\n    margin-right: 5px;\n    font-size: 12px;\n    line-height: 1.1;\n}\ntr.hcol {\n    display: inline-block;\n    vertical-align: top;\n    width: 28%;\n    padding-right: 10px;\n    color: #292929;\n    font-size: 15px;\n}\n\ntr.hcol td {\n    display: block;\n}\n\nspan.val {\n    font-weight: bold;\n    font-size: 19px;\n}\n\n.w44 .val {\n    font-size: 18px;\n    font-weight: bold;\n}\n\n.property--info {\n    display: flex;\n    flex-direction: column;\n}\n\ntable.listing-highlights {\n    width: 100%;\n    position: relative;\n    width: 100%;\n    padding: 8px 12px 8px 0;\n    border-top: solid 1px #ececec;\n    border-bottom: solid 1px #ececec;\n    border-collapse: separate;\n}\n\nul.listing-details {\n    padding: 0px;\n    margin: 10px 0;\n}\n\n.callwrap {\n    display: flex;\n    justify-content: end;\n    gap: 10px;\n}\n\n.callwrap a {\n    background: #d58d8d;\n    padding: 10px;\n    border-radius: 30px;\n    color: #fff;\n}\n\n.property-badge span {\n    background: red;\n    padding: 10px;\n    color: #fff;\n    font-size: 11px;\n}\n"
    }}
  />
</>
)
}
export  default List;