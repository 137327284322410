import React, { useState, useEffect } from 'react';
import {Routes,Route,Link,BrowserRouter} from "react-router-dom";
import List from "./List";
import axios from 'axios';
function  Service()
{
  const [posts, setPosts] = useState([]);
   const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://pickmyproperty.com:4300/get_post');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []); 
  
   useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const responseReviews = await axios.get('http://localhost:4300/get_reviews');
        setReviews(responseReviews.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchTestimonials();
  }, []); 
  
  return(
  
  <>
  
  <Routes>
	<Route path="/list" element={<List />} />
	
	</Routes>
	
   <main id="main">
    {/* ======= Services Section ======= */}
    <section className="section-services section-t8">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrap d-flex justify-content-between">
              <div className="title-box">
                <h2 align="center" className="title-a">Our Services</h2>
				At Pickmyproperty, we offer a comprehensive suite of real estate services designed to meet the unique needs of buyers, sellers, investors, and developers. From residential to commercial projects, our expert team is committed to providing exceptional service every step of the way.

Property Buying and Selling

Personalized property search and market analysis
Expert negotiation and deal closure
End-to-end support from inquiry to purchase
Real Estate Investment Advisory

Investment strategy consulting and portfolio planning
Access to high-potential investment properties
Market trend insights for informed decision-making
Property Management Services

Tenant screening and leasing management
Property maintenance and financial reporting
Maximize rental income and property value
Commercial Real Estate Solutions

Retail, office, and industrial space leasing
Site selection, project planning, and development consulting
Market analysis and feasibility studies
Luxury and High-End Property Services

Exclusive listings and private property viewings
Personalized concierge services for high-end clients
Privacy and security-focused transactions
Legal and Documentation Assistance

Guidance through complex legal and regulatory requirements
Verification and preparation of property documents
Support with registration, taxes, and compliance
Land Acquisition and Development Consulting

Land sourcing and acquisition strategies
Feasibility assessments for residential and commercial projects
Project development planning from concept to completion
Each service is tailored to ensure a smooth and successful transaction, making your real estate journey a rewarding experience. At [Company Name], we’re here to bring your vision to life with integrity, expertise, and a commitment to excellence.
              </div>
            </div>
          </div>
        </div>
      <section className="section-agents section-t8">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrap d-flex justify-content-between">
              <div className="title-box">
                <h2 className="title-a">Latest Projects in Mohali</h2>
              </div>
             
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r5.jpg"
                  alt=""
                  className="img-d img-fluid"
                /><br/>
				<a href="/list">View Detail</a>
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="agent-single.html" className="link-two">
                        Margaret Sotillo
                        <br /> Escala
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r6.jpg"
                  alt=""
                  className="img-d img-fluid"
                />
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="agent-single.html" className="link-two">
                        View Details
                        <br /> Darw
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r7.jpg"
                  alt=""
                  className="img-d img-fluid"
                />
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="agent-single.html" className="link-two">
                        Emma Toledo
                        <br /> Cascada
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
	
       
            
          
          
     
      </div>
    </section>
    {/* End Services Section */}
	
	<section className="section-agents section-t8">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrap d-flex justify-content-between">
              <div className="title-box">
                <h2 className="title-a">Featured Projects in Mohali</h2>
              </div>
              <div className="title-link">
                <a href="/list">
                  All Agents
                  <span className="bi bi-chevron-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r4.jpg"
                  alt=""
                  className="img-d img-fluid"
                />
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="/list" className="link-two">
                        Margaret Sotillo
                        <br /> Escala
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r2.jpg"
                  alt=""
                  className="img-d img-fluid"
                />
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="agent-single.html" className="link-two">
                        Stiven Spilver
                        <br /> Darw
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-box-d">
              <div className="card-img-d">
                <img
                  src="r3.jpg"
                  alt=""
                  className="img-d img-fluid"
                />
              </div>
              <div className="card-overlay card-overlay-hover">
                <div className="card-header-d">
                  <div className="card-title-d align-self-center">
                    <h3 className="title-d">
                      <a href="agent-single.html" className="link-two">
                        Emma Toledo
                        <br /> Cascada
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="card-body-d">
                  <p className="content-d color-text-a">
                    Sed porttitor lectus nibh, Cras ultricies ligula sed magna
                    dictum porta two.
                  </p>
                  <div className="info-agents color-a">
                    <p>
                      <strong>Phone: </strong> +54 356 945234
                    </p>
                    <p>
                      <strong>Email: </strong> agents@example.com
                    </p>
                  </div>
                </div>
                <div className="card-footer-d">
                  <div className="socials-footer d-flex justify-content-center">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="link-one">
                          <i className="bi bi-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
	
	
	
	<section className="section-news section-t8">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrap d-flex justify-content-between">
              <div className="title-box">
                <h2 className="title-a">Latest News</h2>
              </div>
              <div className="title-link">
                <a href="blog-grid.html">
                  All News
                  <span className="bi bi-chevron-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="news-carousel" className="swiper">
          <div className="swiper-wrapper">
    
     {/* End carousel item */}
	 
{posts.map((post) => {
    const dateComponents = post.created_date.split(' ');
    const formattedDate = new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2]);
    return (
        <div className="carousel-item-c swiper-slide">
            <div className="card-box-b card-shadow news-box">
                <div className="img-box-b">
                    <img
                        src="assets/img/post-5.jpg"
                        alt=""
                        className="img-b img-fluid"
                    />
                </div>
                <div className="card-overlay">
                    <div className="card-header-b">
                        <div className="card-category-b">
                            <a href="#" className="category-b">
                                {post.title}
                            </a>
                        </div>
                        <div className="card-title-b">
                            <h2 className="title-2">
                                <a href="blog-single.html">
                                    {post.title} is coming
                                    <br /> new
                                </a>
                            </h2>
                        </div>
                        <div className="card-date">
                            <span className="date-b">{dateComponents[0]}</span>
                        </div>
                        <div className="card-content">
                            <p className="content-b">{post.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})}
{/* End carousel item */}


    
    
          </div>
        </div>
        <div className="news-carousel-pagination carousel-pagination" />
      </div>
    </section>
    {/* End Latest News Section */}
    {/* ======= Testimonials Section ======= */}
    <section className="section-testimonials section-t8 nav-arrow-a">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrap d-flex justify-content-between">
              <div className="title-box">
                <h2 className="title-a">Testimonials</h2>
              </div>
            </div>
          </div>
        </div>
        <div id="testimonial-carousel" className="swiper">
          <div className="swiper-wrapper">
        
            {/* End carousel item */}
				 
{reviews.map((review) => {
    return (
        <div className="carousel-item-a swiper-slide">
            <div className="testimonials-box">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="testimonial-img">
                            <img
                                src="assets/img/testimonial-2.jpg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="testimonial-ico">
                            <i className="bi bi-chat-quote-fill" />
                        </div>
                        <div className="testimonials-content">
                            <p className="testimonial-text">
                                {review.review}
                            </p>
                        </div>
                        <div className="testimonial-author-box">
                            <img
                                src="assets/img/mini-testimonial-2.jpg"
                                alt=""
                                className="testimonial-avatar"
                            />
                            <h5 className="testimonial-author">{review.review_author}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})}
            {/* End carousel item */}
          </div>
        </div>
        <div className="testimonial-carousel-pagination carousel-pagination" />
      </div>
    </section>
	
	  <section className="section-footer">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <div className="widget-a">
            <div className="w-header-a">
              <h3 className="w-title-a text-brand">About us</h3>
            </div>
            <div className="w-body-a">
              <p className="w-text-a color-text-a">
                Welcome to Pickmyproperty, where dreams find their foundation, and every property tells a story. We are a dynamic real estate company dedicated to creating exceptional living and commercial spaces across [Location/City/Country]. With a rich blend of innovation, trust, and personalized service, we bring a fresh perspective to the real estate industry.

Our journey started with a passion for transforming spaces and a vision to make property ownership accessible, transparent, and rewarding. Today, we’re proud to be a trusted name for buyers, sellers, and investors alike. Whether it's residential, commercial, or luxury real estate, we approach every project with a commitment to excellence and a deep understanding of market trends.

              </p>
            </div>
            <div className="w-footer-a">
              <ul className="list-unstyled">
                <li className="color-a">
                  <span className="color-text-a">Phone .</span>{" "}
                  contact@example.com
                </li>
                <li className="color-a">
                  <span className="color-text-a">Email .</span> +54 356 945234
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 section-md-t3">
          <div className="widget-a">
            <div className="w-header-a">
              <h3 className="w-title-a text-brand"> Why Choose Us?</h3>
            </div>
            <div className="w-body-a">
              <div className="w-body-a">
              

Integrity-Driven Service: We believe in transparency, honesty, and dedication at every step.
Customer-Centric Approach: Your needs, dreams, and goals are our top priority.
Market Expertise: Our team of skilled professionals brings local insights and a strategic approach to secure the best deals.
End-to-End Support: From property search to closing deals, our support covers every aspect of the journey.
Innovation and Vision: With cutting-edge technology and a forward-thinking mindset, we redefine modern real estate experiences.
At Pickmyproperty, we don’t just deal in properties; we build relationships and help people find places they can truly call home. Let’s shape the future together!
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 section-md-t3">
          <div className="widget-a">
            <div className="w-header-a">
              <h3 className="w-title-a text-brand">Our Vision</h3>
            </div>
            <div className="w-body-a">
              To transform the real estate experience by setting new standards of trust, innovation, and excellence, creating value for our clients and communities alike. We envision a future where property transactions are seamless, transparent, and accessible to everyone, driven by a commitment to sustainable development and meaningful relationships.

Our goal is not just to build properties but to inspire lifestyle and business opportunities that elevate the quality of life and promote growth. Through innovation, integrity, and a customer-first approach, we aim to lead the industry in redefining what’s possible in real estate.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <nav className="nav-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="#">Home</a>
              </li>
              <li className="list-inline-item">
                <a href="#">About</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Property</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Blog</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
          <div className="socials-a">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="#">
                  <i className="bi bi-facebook" aria-hidden="true" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="bi bi-twitter" aria-hidden="true" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="bi bi-instagram" aria-hidden="true" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="bi bi-linkedin" aria-hidden="true" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copyright-footer">
            <p className="copyright color-text-a">
              © Copyright
              <span className="color-a">EstateAgency</span> All Rights Reserved.
            </p>
          </div>
          <div className="credits">
            {/*
      All the links in the footer should remain intact.
      You can delete the links only if you purchased the pro version.
      Licensing information: https://bootstrapmade.com/license/
      Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=EstateAgency
    */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  </main>
  </>
  )




}
export  default Service;