import React, { useState, useEffect } from 'react';
import TopLayout from './TopLayout';
import SidebarLayout from './SidebarLayout';
import axios from 'axios';
import './blog.css';

const VerifiedList = () => {
  const [verifiedList, setVerifiedList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [statuses, setStatuses] = useState({});

  const fetchData = async () => {
    try {
      const [vendorResponse, userResponse] = await Promise.all([
        axios.get('https://api.pickmyproperty.com/get_vendors_verify'),
        axios.get('https://api.pickmyproperty.com/get_users_verify'),
      ]);

      const vendorList = vendorResponse.data;
      const userListData = userResponse.data;

      setVerifiedList(vendorList);
      setUserList(userListData);

      const initialStatuses = {
        ...vendorList.reduce((acc, vendor) => {
          acc[vendor.id] = vendor.account_status;
          return acc;
        }, {}),
        ...userListData.reduce((acc, user) => {
          acc[user.id] = user.account_status;
          return acc;
        }, {}),
      };

      setStatuses(initialStatuses);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    const id = e.target.getAttribute('data-id');
    const type = e.target.getAttribute('data-type');

    try {
      const endpoint = type === 'vendor' ? 'verfiedVendor' : 'verfiedUser';
      const response = await axios.put(`https://api.pickmyproperty.com/${endpoint}/${id}`, {
        account_status: newStatus,
      });

      if (response.data.message === 'Status updated successfully') {
        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          [id]: newStatus,
        }));
        fetchData(); 
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const renderTable = (list, type) => (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Password</th>
          <th>Account Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.username}</td>
            <td>{item.phone_number}</td>
            <td>{item.email}</td>
            <td>{item.password}</td>
            <td>{item.account_status}</td>
            <td>
              <div className="switch-field">
                <input
                  type="radio"
                  id={`radio-yes-${type}-${item.id}`}
                  name={`switch-one-${item.id}`}
                  value="Active"
                  checked={statuses[item.id] === 'Active'}
                  data-id={item.id}
                  data-type={type}
                  onChange={handleStatusChange}
                />
                <label htmlFor={`radio-yes-${type}-${item.id}`}>Active</label>
                <input
                  type="radio"
                  id={`radio-no-${type}-${item.id}`}
                  name={`switch-one-${item.id}`}
                  value="inActive"
                  checked={statuses[item.id] === 'inActive'}
                  data-id={item.id}
                  data-type={type}
                  onChange={handleStatusChange}
                />
                <label htmlFor={`radio-no-${type}-${item.id}`}>In-Active</label>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="wrapper-full row">
      <div className="wrapper-third col-md-2">
        <SidebarLayout />
      </div>
      <div className="wrapper-seven col-md-10">
        <TopLayout />
        <div className="Properties-table">
          <div className="Properties-table-inner">
            <h2>Vendor List</h2>
            {renderTable(verifiedList, 'vendor')}
            <h2>User List</h2>
            {renderTable(userList, 'user')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedList;
