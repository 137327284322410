import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Routes, Route } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

import TopLayout from './TopLayout';
import SidebarLayout from './SidebarLayout';
import Rentpropertyadd from './Rentpropertyadd';

export default function PropertyInsert() {
	const location = useLocation();
	console.log('location');
	console.log(location.state.id);
	const [showSuccess, setSuccess] = useState(false);
	const [showError, setError] = useState(false);
	const [property, setProperty] = useState(false);
	 const [formData, setFormData] = useState({
	 Id:'',
    propertyType: '',
	propertyLocation: '',
	propertyName: '',
	bedroom: '',
	saleableArea: '',
	unit:'',
	Negotiable:'',
	bathrooms: '',
	balconies: '',
	propertyLocality:'',
	additionalRooms: '',
	propertyPrice: '',
	bookingAmount: '',
	propertyStatus: '',
	possessionDate: '',
	transactionType: '',
	description: '',
	propertyImage: '',
	furnishingStatus: [],
	furnishingOptions: [],
	amenities:[]
  });
	  const [file, setFile] = useState(null);



 useEffect(() => {
        async function fetchPropertyData() {
            try {
                const property_id = location.state.id;
                const response = await fetch(`https://api.pickmyproperty.com/propertyData/${property_id}`);
                const data = await response.json();
                const property_data = data.data;
                console.log(property_data);
                setProperty(property_data);
                setFormData({
					Id: property_data.Id || '',
                    propertyType: property_data.propertyType || '',
                    propertyLocation: property_data.propertyLocation || '',
                    propertyName: property_data.propertyName || '',
                    bedroom: property_data.bedroom || '',
                    saleableArea: property_data.saleableArea || '',
                    unit: property_data.unit || '',
                    Negotiable: property_data.Negotiable || '',
                    bathrooms: property_data.bathrooms || '',
                    balconies: property_data.balconies || '',
                    propertyLocality: property_data.propertyLocality || '',
                    additionalRooms: property_data.additionalRooms || '',
                    propertyPrice: property_data.propertyPrice || '',
                    bookingAmount: property_data.bookingAmount || '',
                    propertyStatus: property_data.propertyStatus || '',
                    possessionDate: property_data.possessionDate || '',
                    transactionType: property_data.transactionType || '',
                    description: property_data.description || '',
                    propertyImage: property_data.propertyImage || '',
                    furnishingStatus: property_data.furnishingStatus || [],
					furnishingOptions: property_data.furnishingOptions || [],
					amenities : property_data.amenities || []
                });
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        }

        fetchPropertyData();
    }, []);
  
  
    // Function to format ISO date string to YYYY-MM-DD format for input display
  const formatDateForInput = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
    const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCheckboxChange = (e) => {
  const { name, value, checked } = e.target;
  const updatedFormData = { ...formData };
  updatedFormData[name] = checked
    ? [...(updatedFormData[name] || []), value]
    : (updatedFormData[name] || []).filter((item) => item !== value);
  setFormData(updatedFormData);
};

   const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
const handleDateChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
	
  console.log('FormData:', formData);
  console.log('File:', file);
  const combinedFormData = new FormData();

  for (const key in formData) {
    combinedFormData.append(key, formData[key]);
  }

  if (file) {
    combinedFormData.append('propertyImage', file);
  }

  console.log('Combined FormData:', combinedFormData);
    try {
	 const id = formData.Id;
      const response = await fetch(`https://pickmyproperty.com:4300/update_property/${id}`, {
        method: 'POST',
        body: combinedFormData,
      });
	  console.log(response)
      if (response.ok) {
		   setSuccess(true);
        console.log('Data submitted successfully');
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
	
  return (
    <>
    <div className="wrapper-full row">
        <div className="wrapper-third col-md-2">
          <SidebarLayout />
        </div>
        <div class="wrapper-seven col-md-10">
          <TopLayout />
    <div className='insert-add'>
    <div className="container">
    <div className="card o-hidden border-0 shadow-lg my-5">
      <form onSubmit={handleSubmit}> 
  <h3>ADMIN PROPERTY DASHBARD</h3>
  <div className="row">
    <div className="col-sm-12">
      <div className="btn-container">
        <button type="button" className="btn btn-danger">
          Sell
        </button>
		<Routes>
		<Route path="/Rentpropertyadd" element={<Rentpropertyadd />} />
		</Routes>
	  <a href="/Rentpropertyadd">
              <button type="button" className="btn btn-default">
                Rent
              </button>
            </a>
      </div>
    </div>
  </div>
  <div className="btn2-container form-container">
    <h3>Your Property type</h3>
    <div className="radio-group">
     <input
  type="radio"
  id="apartment"
  name="propertyType"
  value="Apartment"
  checked={formData.propertyType === 'Apartment'}
  onChange={handleRadioChange}
/>
<label htmlFor="apartment"><i class='fas fa-building'></i> Apartment</label>
      <input
        type="radio"
        id="house"
		onChange={handleRadioChange}
        name="propertyType"
		checked={formData.propertyType === 'House'}
        defaultValue="House"
      />
      <label htmlFor="house"><i class='fas fa-home'></i> House</label>
      <input
        type="radio"
        id="commercial"
		onChange={handleRadioChange}
        name="propertyType"
        defaultValue="Commercial"
      />
      <label htmlFor="commercial"><i class='fas fa-industry'></i>Commercial</label>
      <input type="radio" onChange={handleRadioChange} id="plot" name="propertyType" defaultValue="Plot" />
      <label htmlFor="plot"><i class='far fa-building'></i>Plot</label>
    </div>	
  </div>
  <div className="btn2-container form-container">
    <div className="container-form">
      <h3>Where is Property the Located?</h3>
      <input
        type="location"
        className="form-control"
		onChange={handleInputChange}
		value={formData.propertyLocation}
        name="propertyLocation"
        placeholder="Location/Property"
      />
    </div>
    <div className="container-form">
      <h3>Property Locality</h3>
      <input
        type="text"
		onChange={handleInputChange}
		value={formData.propertyLocality}
        className="form-control"
        name="propertyLocality"
        placeholder="property Locality"
      />
    </div>
	  <div className="container-form">
      <h3>Property Name</h3>
      <input
        type="text"
		onChange={handleInputChange}
		value={formData.propertyName}
        className="form-control"
        name="propertyName"
        placeholder="Property Name"
      />
    </div>
    <div className="container-form">
      <h3>Select Bedrooms</h3>
      <select className="form-control" name="bedroom" onChange={handleSelectChange}>
        <option value={1}>1</option>
        <option value={2} selected="">
          2
        </option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </select>
    </div>
  </div>
  <div className="form-container">
    <h3>Tell us your property area ?</h3>
    <div className="row">
      <div className="col-md-4">
        <input
          type="number"
          className="form-control"
		  onChange={handleInputChange}
		value={formData.saleableArea}
          name="saleableArea"
          placeholder="Saleable area*"
        />
      </div>
      <div className="col-md-2">
        <select className="form-control" name="unit" onChange={handleSelectChange}>
          <option value="Sq. feet">Sq. feet</option>
          <option value="sq_feet">Sq. feet</option>
          <option value="sq_meter">Sq. meter</option>
          <option value="sq_yards">Sq. yards</option>
        </select>
      </div>
    </div>
  </div>
  <div className="form--container">
    <div className="form-container">
      <div className="row">
        <h3>Tell us more about the configuration</h3>
        <div className="col-md-2">
         <select
                        className="form-control"
                        name="bathrooms"
                        value={formData.bathrooms}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select Bathrooms</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="4+">4+</option>
                      </select>
        </div>
        <div className="col-md-2">
          <div className="dropdown">
            <select
                        className="form-control"
                        name="balconies"
                        value={formData.balconies}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select Balconies</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="4+">4+</option>
                      </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="form--container">
    <div className="form-container">
	 <h4>Additional Rooms</h4>
      <div className="checkbox-group">
       
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="type1"
			onChange={handleCheckboxChange}
			checked={formData.additionalRooms.includes('Prayer Room')}
            name="additionalRooms"
            defaultValue="Prayer Room"
          />
          <label htmlFor="type1">Prayer Room</label>
        </div>
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="type2"
			onChange={handleCheckboxChange}
			checked={formData.additionalRooms.includes('Study Room')}
            name="additionalRooms"
            defaultValue="Study Room"
          />
          <label htmlFor="type2">Study Room</label>
        </div>
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="type3"
			onChange={handleCheckboxChange}
            name="additionalRooms"
            defaultValue="Store Room"
			checked={formData.additionalRooms.includes('Store Room')}
          />
          <label htmlFor="type3">Store Room</label>
        </div>
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="type4"
			onChange={handleCheckboxChange}
            name="additionalRooms"
            defaultValue="Servant Room"
			checked={formData.additionalRooms.includes('Servant Room')}
          />
          <label htmlFor="type4">Servant Room</label>
        </div>
      </div>
    </div>
  </div>
  <div className="form-container">
    <hr className="solid" />
    <div className="row">
      <div className="col-md-4">
        <div className="space-container">
          <h3>What is the expected price</h3>
          <div className="space-container">
            <input
              type="price"
              name="propertyPrice"
			   onChange={handleInputChange}
		value={formData.propertyPrice}
              className="form-control"
              placeholder="Price /sq.feet"
            />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
				name="Negotiable"
				onChange={handleCheckboxChange}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Negotiable
              </label>
            </div>
            <button type="button" className="btn btn-link">
              +add price details
            </button>
          </div>
          <div className="space-container">
            <input
              type="price"
			  onChange={handleInputChange}
		value={formData.bookingAmount}
              name="bookingAmount"
              className="form-control"
              placeholder="Booking Amount"
            />
          </div>
        </div>
      </div>
    </div>
	<h3>Property Status</h3>
    <div className="radio-group">
      
      <input
        type="radio"
        id="ready"
        name="propertyStatus"
		onChange={handleRadioChange}
		checked={formData.propertyStatus.includes('Ready To Move In')}
        defaultValue="Ready To Move In"
      />
      <label htmlFor="ready">Ready To Move In</label>
      <input
        type="radio"
        id="construction"
        name="propertyStatus"
		onChange={handleRadioChange}
		checked={formData.propertyStatus.includes('Under Construction')}
        defaultValue="Under Construction"
      />
      <label htmlFor="construction">Under Construction</label>
    </div>
    <div className="space-container">
      <h4>Possession Date</h4>
       <input 
            type="date" 
            name="possessionDate" 
            onChange={handleDateChange} 
            value={formatDateForInput(formData.possessionDate)} 
            className="form-control" 
          />
    </div>
    <div className="space-container">
	   <h3>Transaction type</h3>
      <div className="radio-group">
     
        <input
          type="radio"
          id="New"
          name="transactionType"
		  onChange={handleRadioChange}
		  checked={formData.transactionType.includes('New')}
          defaultValue="New"
        />
        <label htmlFor="New">New</label>
        <input
          type="radio"
          id="Resale"
          name="transactionType"
		  onChange={handleRadioChange}
		  checked={formData.transactionType.includes('Resale')}
          defaultValue="Resale"
        />
        <label htmlFor="Resale">Resale</label>
      </div>
    </div>
    <div className="space-container">
      <div className="form-group">
        <label htmlFor="comment">Description</label>
        <textarea
          className="form-control"
          rows={5}
          id="comment"
          name="description"
		  onChange={handleInputChange}
		value={formData.description}
          placeholder="tell us more about property..."
          defaultValue={""}
        />
      </div>
      <div className="form-group file_upload_images">
        <label htmlFor="propertyImage">Property Image</label>
        <input
          type="file"
          className="form-control-file"
          name="propertyImage"
		  onChange={handleFileChange}
          id="propertyImage"
          accept="image/*"
		  multiple
        />
        <small className="form-text text-muted">
          Upload an image of the property.
        </small>
      </div>
    </div>
    <h4>Property Features</h4>
    <h3>Choose furnishing status</h3>
    <div className="row">
      <div className="col-sm-12">
        <div className="btn-container">
          <div className="radio-group">
            <input
              type="radio"
              name="furnishingStatus"
			  onChange={handleRadioChange}
              defaultValue="Unfurnished"
			  checked={formData.furnishingStatus.includes('Unfurnished')}
              id="Unfurnished"
            />{" "}
            <label htmlFor="Unfurnished">Unfurnished</label>
            <input
              type="radio"
              name="furnishingStatus"
			  onChange={handleRadioChange}
              defaultValue="Semi Furnished"
              id="semiFurnished" 
			  checked={formData.furnishingStatus.includes('Semi Furnished')}
            />{" "}
            <label htmlFor="semiFurnished">Semi Furnished</label>
            <input
              type="radio"
              name="furnishingStatus"
			  onChange={handleRadioChange}
              defaultValue="Fully Furnished"
              id="Fully_Furnished"
			  checked={formData.furnishingStatus.includes('Fully_Furnished')}
            />{" "}
            <label htmlFor="Fully_Furnished">Fully Furnished</label>
          </div>
        </div>
      </div>
    </div>
  <div className="row">
  <h3>Additional furnishing</h3>
    <div className="checkbox-group">
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_sofa"
              checked={formData.furnishingOptions.includes('furnishing_icon_sofa')}
              id="Sofa"
            />{" "}
            <label htmlFor="Sofa"><i className='fas fa-couch'></i> Sofa</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_fridge"
              checked={formData.furnishingOptions.includes('furnishing_icon_fridge')}
              id="Refrigerator"
            />{" "}
            <label htmlFor="Refrigerator"><i className='fas fa-couch'></i> Refrigerator</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_dining"
              checked={formData.furnishingOptions.includes('furnishing_icon_dining')}
              id="Dining_Table"
            />{" "}
            <label htmlFor="Dining_Table"><i className='fas fa-couch'></i> Dining Table</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_gas"
              checked={formData.furnishingOptions.includes('furnishing_icon_gas')}
              id="Gas_Connection"
            />{" "}
            <label htmlFor="Gas_Connection"><i className='fas fa-couch'></i> Gas Connection</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_washing"
              checked={formData.furnishingOptions.includes('furnishing_icon_washing')}
              id="Washing_Machine"
            />{" "}
            <label htmlFor="Washing_Machine"><i className='fas fa-couch'></i> Washing Machine</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_wifi-connection"
              checked={formData.furnishingOptions.includes('furnishing_icon_wifi-connection')}
              id="Wifi_Connection"
            />{" "}
            <label htmlFor="Wifi_Connection"><i className='fas fa-couch'></i> Wifi Connection</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_microwave"
              checked={formData.furnishingOptions.includes('furnishing_icon_microwave')}
              id="Microwave"
            />
            <label htmlFor="Microwave"> <i className='fas fa-couch'></i> Microwave</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name="furnishingOptions[]"
              value="furnishing_icon_modular-kitchen"
              checked={formData.furnishingOptions.includes('furnishing_icon_modular-kitchen')}
              id="Modular_Kitchen"
            />{" "}
            <label htmlFor="Modular_Kitchen"><i className='fas fa-couch'></i> Modular Kitchen</label>
          </div>
        </div>

</div>

    <div className="row">
  <h4>Choose Amenities</h4>
  <div className="checkbox-group">
    <div className="checkbox-item">
      <input
        type="checkbox"
		onChange={handleCheckboxChange}
        name="Amenities[]"
        defaultValue="car_parking"
        id="Parking"
		checked={formData.amenities.includes('car_parking')}
      />{" "}
      <label htmlFor="Parking"><i class='fas fa-car'></i>	 Car Parking</label>
    </div>
    <div className="checkbox-item">
      <input
        type="checkbox"
		onChange={handleCheckboxChange}
        name="Amenities[]"
        defaultValue="power_backup"
		checked={formData.amenities.includes('power_backup')}
        id="Backup"
      />{" "}
      <label htmlFor="Backup"><i class='fas fa-charging-station'></i>	 Power Backup</label>
    </div>
    <div className="checkbox-item">
      <input
        type="checkbox"
		onChange={handleCheckboxChange}
        name="Amenities[]"
        defaultValue="24x7_security"
        id="Security"
		checked={formData.amenities.includes('24x7_security')}
      />{" "}
      <label htmlFor="Security"><i class='fas fa-charging-station'></i>24 x 7 Security</label>
    </div>
    <div className="checkbox-item">
      <input type="checkbox" name="Amenities[]" defaultValue="lift" checked={formData.amenities.includes('lift')} id="Lift" />{" "}
      <label htmlFor="Lift"><i class='fas fa-charging-station'></i>  Lift</label>
    </div>
    <div className="checkbox-item">
      <input
        type="checkbox"
		onChange={handleCheckboxChange}
        name="Amenities[]"
        defaultValue="swimming_pool"
        id="Swimming"
		checked={formData.amenities.includes('swimming_pool')}
      />{" "}
      <label htmlFor="Swimming"> <i class='fas fa-charging-station'></i> Swimming Pool</label>
    </div>
    <div className="checkbox-item">
      <input
        type="checkbox"
		onChange={handleCheckboxChange}
        name="Amenities[]"
        defaultValue="landscaped_gardens"
        id="Landscaped_Gardens"
		checked={formData.amenities.includes('landscaped_gardens')}
      />{" "}
      <label htmlFor="Landscaped_Gardens"><i class='fas fa-charging-station'></i> Landscaped Gardens</label>
    </div>
    <div className="checkbox-item">
      <input type="checkbox" onChange={handleCheckboxChange} name="Amenities[]" checked={formData.amenities.includes('gym')} defaultValue="gym" />{" "}
      <label htmlFor="Gym"><i class='fas fa-walking'></i> Gym</label>
    </div>
  </div>
</div>
{showSuccess && (
		<div className="text-danger mt-2">
		Property added successfully
		</div>
		)}
		{showError && (
		<div className="text-danger mt-2">
		Please fill in all required fields
		</div>
		)}
<div className="border--bottom">
    <input type="submit" className="button-submit" name="property_add" defaultValue="Submit" />
	</div>
  </div>
</form>
 </div>
                  </div>
                  </div>
                  </div>
                  </div>
    </>
      
    
  )
}
