import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userType = sessionStorage.getItem('userType');

    if (!userType || userType !== 'user') {
      navigate('/Loginpanel');
    }
  }, [navigate]);
};

export default useAuth;