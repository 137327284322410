import React, { useState, useEffect } from 'react';
import TopLayout from './TopLayout';
import SidebarLayout from './SidebarLayout';
import axios from 'axios';
import './blog.css';

const VerifiedProperties = ({ property }) => {
  const [verifiedProperties, setVerifiedProperties] = useState([]);
  const [statuses, setStatuses] = useState({});

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get('https://api.pickmyproperty.com/get_properties_verify');
        const properties = response.data;
        setVerifiedProperties(properties);

        const initialStatuses = properties.reduce((acc, property) => {
          acc[property.p_id] = property.p_approved;
          return acc;
        }, {});
        setStatuses(initialStatuses);
		
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchProperties();
  }, []);

  const serverUrl = 'https://api.pickmyproperty.com/';

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    const propertyId = e.target.getAttribute('data-propid');

    try {
      const response = await axios.put(`https://api.pickmyproperty.com/verfiedproperty/${propertyId}`, {
        p_approved: newStatus,
      });

      if (response.data.message === 'Status updated successfully') {
        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          [propertyId]: newStatus,
        }));
		window.location.reload();
      } else {
        console.error('Failed to update property status');
      }
    } catch (error) {
      console.error('Error updating property status:', error);
    }
  };

  return (
    <>
      <div className="wrapper-full row">
        <div className="wrapper-third col-md-2">
          <SidebarLayout />
        </div>
        <div className="wrapper-seven col-md-10">
          <TopLayout />
          <div className="Properties-table">
            <div className="Properties-table-inner">
              <h2>Verfiy Properties By documents</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Property ID</th>
                    <th>Property Name</th>
                    <th>Property Vendor</th>
                    <th>Property Location</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Customer Email</th>
                    <th>Property Docs</th>
                    <th>Approved</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {verifiedProperties.map((property) => (
                    <tr key={property.id}>
                      <td>{property.id}</td>
                      <td>{property.p_id}</td>
                      <td>{property.p_name}</td>
                      <td>{property.p_vendor}</td>
                      <td>{property.p_Location}</td>
                      <td>{property.p_customername}</td>
                      <td>{property.p_phone}</td>
                      <td>{property.p_email}</td>
                      <td>
                        {property.p_image && JSON.parse(property.p_image).map((image, index) => (
                          <img
                            key={index}
                            src={`${serverUrl}/uploads/${image}`}
                            alt="Property"
                            style={{ width: '50px', height: '50px', marginRight: '5px' }}
                          />
                        ))}
                      </td>
                      <td>{property.p_approved}</td>
                      <td>
                        <div className="switch-field">
                          <input
                            type="radio"
                            id={`radio-yes-${property.p_id}`}
                            name={`switch-one-${property.p_id}`}
                            value="Yes"
                            checked={statuses[property.p_id] === 'Yes'}
                            data-propid={property.p_id}
                            onChange={handleStatusChange}
                          />
                          <label htmlFor={`radio-yes-${property.p_id}`}>Yes</label>
                          <input
                            type="radio"
                            id={`radio-no-${property.p_id}`}
                            name={`switch-one-${property.p_id}`}
                            value="No"
                            checked={statuses[property.p_id] === 'No'}
                            data-propid={property.p_id}
                            onChange={handleStatusChange}
                          />
                          <label htmlFor={`radio-no-${property.p_id}`}>No</label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifiedProperties;
