// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-inner img {
    max-width: 70px;
}

.svg-icon {
    text-align: center;
    display: block;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Component/modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW;AACf","sourcesContent":[".logo-inner img {\r\n    max-width: 70px;\r\n}\r\n\r\n.svg-icon {\r\n    text-align: center;\r\n    display: block;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
