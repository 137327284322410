import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './profile.css';
import logo from '../site_logo-site.jpg';

export default function Frontenduser() {
  const [profileData, setProfileData] = useState(null);
  const [referralUsers, setReferralUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [secactiveTab, setsecactiveTab] = useState(10);
  
  const phoneNumber = sessionStorage.getItem('phoneNumber');
  console.log(phoneNumber);

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const profile_id = phoneNumber;
        const response = await fetch(`https://api.pickmyproperty.com/profile/${profile_id}`);
        const data = await response.json();
        const profile_data = data.data;
        setProfileData(profile_data);
        const datareferralCode = profile_data.referralCode;
        const referralResponse = await fetch(`https://api.pickmyproperty.com/referralUsers/${datareferralCode}`);
        const referralData = await referralResponse.json();
        console.log(referralData.data);
        setReferralUsers(referralData.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    fetchProfileData();
  }, []);

  const copyToClipboard = () => {
    const referralLink = document.querySelector('.link-copy').textContent;
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('Copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const [name, setName] = useState('Login');
  const navigate = useNavigate();

  useEffect(() => {
    const storedValue = sessionStorage.getItem('phoneNumber');
    const userType = sessionStorage.getItem('userType');
    const username = sessionStorage.getItem('username');
    console.log('Stored value:', userType);
    setName(username);
  }, []);

  const logout = () => {
    sessionStorage.clear();
    navigate('/Loginpanel');
  };

  return (
    <> 
      <div className="header">
        <div className="row-header">
          <div className="row-col-logo">
            <div className="logo-inner">
              <Link to="/">
                <img src={logo} alt="Site Logo" />
              </Link>
            </div>
          </div>
          <div className="row-col-menu">
            <ul>
              <li className="loan-app">
                <img
                  src="https://static.makaan.com/images/personalLoansHooks/Personal_Loan_Desktop_Home_Nav.22990e95.png?width=96&height=20"
                  alt="Loan App"
                />
              </li>
              <li className="download-s-app">
                <span
                  data-url="/download-mobileapp"
                  data-link-type="Other"
                  data-link-name="download app"
                  data-type="download-app"
                  title=""
                  className="d-show span-link"
                >
                  <span className="app-img" />
                  <span className="txt"> Download app</span>
                </span>
              </li>
              <li className="sellersitelink">
                <a
                  href="https://seller.makaan.com/postProperty"
                  data-link-type="Other"
                  data-link-name="list your property"
                  data-type="track-sell-your-property"
                  target="_blank"
                >
                  <span className="list-icon" />
                  <span className="txt">
                    Sell/Rent Property<sup> Free</sup>
                  </span>
                </a>
              </li>
              <li className="login">
                <Link to="/Loginpanel">{name}</Link>
              </li>
              {name !== 'Customer Login' && (
                <li className="log-out" onClick={logout}>
                  <span>Logout</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className="profile-tab-data">
        <div className="container_tabs_data">
          {profileData && (
            <>
			<div className="tabs-full">
			<div class="row">
			<div class="col-md-4">
			<div class="list_tabs">
			<h3>Account </h3>
			 <ul className="tab-list">
                  <li className={`tab-item ${secactiveTab === 10 ? 'active' : ''}`} onClick={() => setsecactiveTab(10)}>Wallet Details</li>
                  <li className={`tab-item ${secactiveTab === 11 ? 'active' : ''}`} onClick={() => setsecactiveTab(11)}>Reset Password</li>
                </ul>
			</div>
			</div>
			<div class="col-md-8">
			<div class="list_tab_content">
			
			 <div className="tab-content">
			{secactiveTab === 10 && (
			<div className="person-box--right">
                    <div className="referral-users referralData-cls">
                  <a href="#" className="block-referral">
                            <div className="right--content">
							<div class="Userpoint">
							 <p>Rupees</p>
                              <p>₹{profileData.Userpoint}</p>
							</div>
							
							  <div class="add-wallet">
							  +Add Money To Wallet
							  </div>
                            </div>
                          </a>
                    </div>
					</div> 
                  )}
				  {secactiveTab === 11 && (
                    <div className="referral-users">
                  Reset Password
                    </div>
                  )}
				   </div>
			
				   
			</div>
			</div>
			</div>
			</div>
              <div className="tabs row">
			  <div class="col-md-4">
			  <div class="list_tabs">
			  <h3>Account Details </h3>
			  <ul className="tab-list">
                  <li className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Profile</li>
                  <li className={`tab-item ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Referral Users</li>
				  <li className={`tab-item ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>Referral Details</li>
				  <li className={`tab-item ${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}>Sub Wallet</li>
                  <li className={`tab-item ${activeTab === 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}>Notification</li>
				  
				 
                </ul>
			  </div>
			  
			  </div>
                 <div class="col-md-8 ">
				 <div class="list_tab_content">
				 <div className="tab-content">
                  {activeTab === 1 && (
                    <div className="profile-container">
                      <div className="person-box">
                        <div className="person-box--left">
                          <div className="person-img">
                            <img
                              src="https://i.ibb.co/tp1vQ8T/man-person-icon.png"
                              alt="Jessica Potter"
                            />
                          </div>
                          <div className="person-name">
                            <table className="table-profile">
                              <tbody>
                                <tr>
                                  <td>Username</td><td>{profileData.username}</td>
                                </tr>
                                <tr>
                                  <td>Phone Number</td><td>{profileData.phone_number}</td>
                                </tr>
                                <tr>
                                  <td>Email</td><td>{profileData.email}</td>
                                </tr>
                                <tr>
                                  <td>Type</td><td>{profileData.type}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="links">
                            <ul>
                              <li>
                                <a href="https://codepen.io/batuhangulgor" target="_blank">
                                  Follow
                                </a>
                              </li>
                              <li>
                                <a href="mailto:gulgor.batu@gmail.com">Message</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div className="referral-users">
                    
                      {referralUsers && referralUsers.length > 0 && (
                        <ul className="associated_users">
                          {referralUsers.map((user, index) => (
                            <li key={index}>
                              <p className="user-nfo">
                                <span>Name : {user.username}</span>
                                <span>Referral Code : {user.referralCode}</span>
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
				    {activeTab === 3 && (
                    <div className="profile-container">
                      <div className="person-box">
                        <div className="person-box--right">
                          <a href="#" className="block-referral">
                            <div className="right--content">
							 <p>Rupees</p>
                              <p>₹{profileData.Userpoint}</p>
                             
                            </div>
                          </a>
                          <a href="#" className="block-referral">
                            <div className="right--content">
							<p>Cashback incentive</p>
                              <p>{profileData.referralPoint}</p>
                              
                            </div>
                          </a>
                          <a href="#" className="block-referral">
                            <div className="right--content">
							<p>Referral Code</p>
                              <p>{profileData.referralCode}</p>
                              
                            </div>
                          </a>
                          <a href="#" className="block-referral" onClick={copyToClipboard}>
                            <div className="right--content">
                              <p>Referral link</p>
                              <p className="link-copy">{`http://pickmyproperty.com:3000/Loginpanel?refer=${profileData.referralCode}`}</p>
                              <span className="copycode">Copy</span>
							  
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
				  {activeTab === 4 && (
                     <div className="person--box--right">
                          <a href="#" className="block--referral">
                            <div className="content-right">
							 <p>Rupees From Admin</p>
                              <p>₹{profileData.Userpoint}</p>
                             
                            </div>
                          </a>
                          <a href="#" className="block--referral">
                            <div className="content-right">
							<p>Cashback Earn From User Create </p>
                              <p>{profileData.referralPoint}</p>
                              
                            </div>
                          </a>
                        </div>
                  )}
				  {activeTab === 5 && (
                    <div className="referral-users">
                      <span class="Notification"> No New Notification</span>
                     
                    </div>
                  )}
                </div>
				</div>
				 </div>
                
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
