import React, { useState, useEffect } from 'react';
import TopLayout from './TopLayout';
import SidebarLayout from './SidebarLayout';
import axios from 'axios';
import './blog.css';
import { useNavigate } from 'react-router-dom';
const CustomerpropertyList = ({ property }) => {
  const [verifiedProperties, setVerifiedProperties] = useState([]);
  const [statuses, setStatuses] = useState({});
 const navigate= useNavigate();
  useEffect(() => {
    const fetchProperties = async () => {
		
      try {
        const response = await axios.get('http://localhost:4300/verifiedProperties');
        const properties = response.data;
        setVerifiedProperties(properties);

        const initialStatuses = properties.reduce((acc, property) => {
          acc[property.p_id] = property.p_approved;
          return acc;
        }, {});
        setStatuses(initialStatuses);
		
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchProperties();
  }, []);

  const serverUrl = 'http://localhost:4300';

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    const propertyId = e.target.getAttribute('data-propid');

    try {
      const response = await axios.put(`http://localhost:4300/verfiedproperty/${propertyId}`, {
        p_approved: newStatus,
      });

      if (response.data.message === 'Status updated successfully') {
        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          [propertyId]: newStatus,
        }));
		window.location.reload();
      } else {
        console.error('Failed to update property status');
      }
    } catch (error) {
      console.error('Error updating property status:', error);
    }
  };
  
  
  
  
const handleCustomer = (phonenumber) => {
	 navigate("/CustomerDetails",{state:{phonenumber:phonenumber}})
  };

  return (
    <>
      <div className="wrapper-full row">
        <div className="wrapper-third col-md-2">
          <SidebarLayout />
        </div>
        <div className="wrapper-seven col-md-10">
          <TopLayout />
          <div className="Properties-table">
            <div className="Properties-table-inner">
              <h2>Verfiy Properties By documents</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Property ID</th>
                    <th>Property Name</th>
                    <th>Property Vendor</th>
                    <th>Property Location</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Customer Email</th>
                    <th>Images</th>
                    <th>Customer Details</th>
                  </tr>
                </thead>
                <tbody>
                  {verifiedProperties.map((property) => (
                    <tr key={property.id}>
                      <td>{property.id}</td>
                      <td>{property.p_id}</td>
                      <td>{property.p_name}</td>
                      <td>{property.p_vendor}</td>
                      <td>{property.p_Location}</td>
                      <td>{property.p_customername}</td>
                      <td>{property.p_phone}</td>
                      <td>{property.p_email}</td>
                      <td>
                        {property.p_image && JSON.parse(property.p_image).map((image, index) => (
                          <img
                            key={index}
                            src={`${serverUrl}/uploads/${image}`}
                            alt="Property"
                            style={{ width: '50px', height: '50px', marginRight: '5px' }}
                          />
                        ))}
                      </td>
                      <td>
					 <button className="btn-success " onClick={() => handleCustomer(property.p_phone)}>Customer Details</button>
					
                     
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerpropertyList;
